const translations = {
  html_title_text: "Ishli - Профессионалы по обслуживанию дома и отзывы",

  your_points: "Ваши очки",
  computer: "Компьютер",
  checkmate_in: "шах и мат в",
  steps: "шага",

  points: "балл",
  you_will_earn: "Вы заработаете",

  view: "Перейти",
  browse_classes: "Browse Classes",

  join: "Вступить",
  complete_payment: "Complete Payment",
  upload: "Upload",

  you: "Вы",
  with: "с",
  class_starts: "Урок начинается:",

  how_can_we_help: "Как мы можем помочь?",
  help: "Помощь",
  delivery_cost: "Стоимость доставки",
  minutes: "Минуты",
  all_rights_reserved: "Все права защищены",

  tax: "Налог",
  start_chat: "Открыть чат",
  close: "Закрыть",
  under_development: "Активно разрабатывается",

  country: "Страна",
  city: "Город",
  floor: "Этаж",
  unit: "Квартира",
  entrance: "Подъезд",
  zip: "Почтовый индекс",

  exact_time: "Точное время",
  enter_verification_code: "Введите проверочный код",
  all_products_will_be_removed: "Все товары будут удалены из вашей корзины",

  deliver_to: "Доставить",
  items: "Товары",

  occasion: "повод",
  date: "Дата",
  type_occasion: "Введите имя случая",
  set_reminder: "Установить напоминание",

  email_us: "Свяжитесь с нами",
  cities: "Города",

  contact_information: "Контактная информация",
  password_details: "Детали пароля",
  resend_confirmation: "Переслать подтверждение",

  due: "Итог",
  discount: "Скидка",
  delivery_fee: "Стоимость Доставки",
  service_fee: "Плата за услуги",

  html_title_text: "Instabuket.com - Торговая площадка",

  edit_review: "Редактировать",

  are_you_sure_to_cancel: "Вы уверены, что отмените?",
  comment: "Комментарий",
  buy_now: "Купить сейчас",
  add_to_cart: "Добавить в корзину",
  loading: "Загрузка...",
  no_reviews: "Нет Отзывов",
  num_of_reviews: "оценок",

  thank_you_for_your_order: "Спасибо за ваш заказ",
  how_was_your_experience: "Как прошел ваш опыт?",
  quality: "Качество",
  communication: "Общение",
  add_your_comment: "Добавьте свой комментарий",
  submit: "Отправлять",

  choose_city: "Выбрать город",
  order_with_number: "Заказ#%{number}",
  enter_new_password: "Введите новый пароль",
  password_confirmation: "Подтверждение пароля",
  confirm: "Подтвердить",
  password_reset_success: "Пароль успешно изменен",
  continue: "Продолжить",
  not_yet: "Не сейчас",
  rate: "Rate",
  cancel: "Отменить",
  locale: "Язык",
  success_sent_confirmation: "success_sent_confirmation",
  success_email_confirm: "Successfully confirmed email!",
  current_password: "Действующий пароль",
  order_number: "Номер заказа",
  communication_rating: "Communication Rating",
  delivery_rating: "Delivery Rating",
  quality_rating: "Quality Rating",
  add_review: "Добавить Отзыв",
  in_review: "In Review",
  approved: "Approved",
  order_cancelled: "Order is cancelled!",

  enter_city: "Введите город",
  enter_address: "Введите адрес",
  cart: "КОРЗИНА",
  reset: "Очистить",
  and: "и",
  account: "Личный кабинет",
  write: "Написать",
  write_dots: "Написать ...",
  any_time: "Любое время",
  filters: "Фильтры",
  select_date_and_hour: "Выберите Дату и Час",
  enter_and_verify_phone: "Введите и подтвердите свой номер телефон",
  edit_recipient: "Редактирование получателя",
  request_address_from_recipient: "Запросить адрес от получателя!",

  credit_card: "Кредитная Карта",
  apple_pay: "Apple Pay",
  cash: "Наличные",
  recipients: "Получатели",
  postcard: "Открытка",
  want_to_send_postcard: "Вы хотите отправить открытку?",

  price: "Цена",
  size: "Размер",
  color: "Цвет",
  flowers: "Цветы",
  occasions: "Повод",
  products: "Товары",

  remember_me: "Запомнить меня",
  or: "Или",
  register: "Регистрация",
  send_password: "Отправить пароль",
  go_back: "Возвращаться",
  enter_your_phone: "Введите свой телефон",

  first_name: "Имя",
  last_name: "Фамилия",
  phone: "Телефон",
  address: "Адрес",
  directions: "Инструкции по доставке",
  new_recipient: "Новый получатель",

  profile: "Профиль",
  orders: "Заказы",
  reviews: "Отзывы",
  favorites: "Избранные",
  delivery: "Доставка",
  pickup: "Самовывоз",
  become_partner: "Стать партнером",
  track_order: "Отследить ваш заказ",

  number: "Номер",
  recipient: "Получатель",
  amount: "Сумма",
  status: "Статус",
  actions: "Действия",

  shop: "Магазин",

  created_at: "Создано в",
  updated_at: "Обновлен в",
  pending: "В ожидании",
  accepted: "Принят",
  in_progress: "Выполняется",
  in_courier: "Передан курьеру",
  in_route: "В пути",
  fulfilled: "Доставлен",
  cancelled: "Отменен",

  terms: "Условиями",
  privacy_policy: "Политики Конфиденциальности",
  agree_to_terms: "Регистрируясь, вы соглашаетесь с нашими",
  promotional_text:
    "Да! Я хотел бы получать обновления, рекламные предложения от BUKETU.",

  welcome_step_one: "Добро пожаловать в Buketu",
  welcome_step_two: "Чат с магазинами",
  welcome_step_three: "Отслеживать заказы на карте",

  select_recipient: "Выберите получателя",

  pay_with_apple: "Оплатить с Apple  %{amount}",
  pay_with_cash: "Оплатить наличными %{amount}",
  pay_with_card: "Оплатить картой %{amount}",

  add_card: "Добавить карту",
  scan_card: "Сканировать карту",
  go_to_cart: "Перейти в корзину",

  small: "маленький",
  medium: "средний",
  large: "большой",

  chat: "Чат",
  total: "Сумма",
  cart_is_empty: "Корзина пуста",
  continue_shopping: "Продолжить покупки",
  order_placed: "Заказ создан!",
  will_inform_abouut_order: "Мы сообщим вам, как только магазин примет заказ",

  next: "Следующий",
  back: "Назад",

  height: "Высота",
  width: "Ширина",

  active_orders: "Активные заказы",
  fulfilled_orders: "Выполненные заказы",

  checkout: "Корзинка",
  pay: "Оплатить",

  no_favorites: "У вас еще нет избранных",

  settings: "Настройки",
  cards: "Кредитные карты",
  notifications: "Уведомления",
  languages: "Язык",
  currency: "Валюта",
  password: "Пароль",
  signin: "Войти в систему",
  logout: "Выйти",
  timezone: "Часовой пояс",
  english: "Английский",
  russian: "Русский",
  uzbek: "Узбекский",
  success: "Успешно сохранено",
  email: "Эл.почта",
  signup: "Зарегистрироваться",
  create_one: "Создайте",
  have_account: "У вас есть аккаунт?",
  dont_have_account: "У вас нет аккаунта?",
  forgot_password: "Напомнить",
  send: "Отправить",

  login: "Войти",
  auth_failed: "Ошибка входа",
  login_auth_failed: "Неправильный адрес электронной почты или пароль",
  email_sent_success: "Успешно отправлено письмо",

  unauthorized: "Вы не авторизованы для выполнения этого действия",
  too_many_request: "Вы делаете много запросов",
  not_found: "Мы не смогли найти ресурс, который вы просили",
  add_payment_method: "Добавить способ оплаты",
  payment_added: "Способ оплаты добавлен",

  card_number: "НОМЕР КАРТЫ",
  expiry: "ГОДЕН ДО",
  cvc: "CVC/CCV",
  card_holder_name: "ИМЯ ВЛАДЕЛЬЦА КАРТЫ",
  postal_code: "ПОЧТОВЫЙ ИНДЕКС",
  fullname: "Полное имя",

  save: "Сохранить",
  server_error:
    "У нас возникли некоторые проблемы. Пожалуйста, попробуйте еще раз позже!",

  login_to_your_account: "Вход в свой аккаунт",
};

export default translations;
