import React, { Suspense, useContext, createContext, useState } from "react";

import { Route, Routes } from "react-router-dom";

import Main from "app/screens/main";
import Privacy from "app/screens/privacy";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  );
}
