import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import I18n from "app/utils/i18n-js";
import { getCookie } from "app/cookie";
import { en, uz, ru } from "app/translations";

import App from "./app";
import reportWebVitals from "./reportWebVitals";

I18n.fallbacks = true;
I18n.translations = { en, uz, ru };
I18n.locale = getCookie("locale") || "en";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
